import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import CourseImg5 from "../../assets/img/courses/Course5.png";

export default function ReikiCrystalBasic() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This navigates the user back to their last page
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Reiki Crystal Basic</Title>
          <CourseImage5Styled src={CourseImg5} alt="Reiki Crystal Basic" />{" "}
        </MainHeading>
        <SecondaryHeading>
          <Message>
            🧐 Getting ready for you! We are planning to give you more. Stay
            tuned!
          </Message>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px;
  background-color: #f4f4f4;
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; /* Increased max-width for better content fitting on large screens */
  margin: 0 auto;
  text-align: center;
  padding: 0 20px; /* Added horizontal padding for smaller screens */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-top: 40px; /* Adjust margin-top for tablets */
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
    margin-top: 60px; /* Further increase margin-top for mobile devices */
  }
`;

const CourseImage5Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #333;
  background-color: #e8e0f8; /* Light purple background */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  margin: 20px auto; /* Center it */
  max-width: 90%; /* Increase width */
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-3px); /* 3D effect */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px; /* Slightly larger space for tablets */
  }

  @media (max-width: 480px) {
    margin-top: 20px; /* Slightly smaller space for very small screens (mobile) */
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute; /* Fixed position at the top of the screen */
  top: 20px; /* Positioned slightly down from the top edge */
  left: 20px; /* Positioned on the left side */
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px; /* Rounded pill shape */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100; /* Ensures button stays on top of content */

  display: flex;
  align-items: center; /* Aligns icon and text */
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px); /* Small slide effect on hover */
  }

  &:hover svg {
    transform: translateX(-5px);
    transition: transform 0.3s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    top: 10px; /* Adjust position for smaller screens */
    left: 15px;
    font-size: 1rem; /* Smaller font size for mobile */
    padding: 8px 16px; /* Reduce padding for mobile */
  }

  @media (max-width: 480px) {
    top: 15px; /* Adjust top spacing for very small screens */
    left: 10px;
    font-size: 0.9rem; /* Smaller font size */
    padding: 8px 12px; /* Further reduce padding */
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px; /* Default space for larger screens */
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px; /* Increase space between button and title */
  }

  @media (max-width: 480px) {
    bottom: 80px; /* Increase further for very small screens */
  }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import { Link } from "react-router-dom";

import CourseImg1 from "../../assets/img/courses/Course1.png";
import CourseImg2 from "../../assets/img/courses/Course2.png";
import CourseImg4 from "../../assets/img/courses/Course4.png";
import CourseImg5 from "../../assets/img/courses/Course5.png";
import CourseImg6 from "../../assets/img/courses/Course6.png";
import Reiki3rdDegree from "../../assets/img/courses/Reiki-3rd-Degree.png";

export default function Courses() {
  // JavaScript smooth scroll adjustment
  useEffect(() => {
    const handleHashChange = () => {
      const yOffset = -80; // Height of the fixed navbar
      const element = document.getElementById(
        window.location.hash.substring(1)
      );
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  return (
    <Wrapper id="courses">
      <AnchorOffset /> {/* Use AnchorOffset here for smooth scrolling */}
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <Title>Our Transformative Courses</Title>
            <Description>
              Our transformative courses at Amruttam Reiki Yoga offer in-depth
              learning in Reiki, meditation, and spiritual practices, empowering
              you to heal and lead a balanced, fulfilling life.
            </Description>
          </HeaderInfo>
          <CourseGrid>
            <CourseBox>
              <Link to="/reiki-course">
                <ProjectBox
                  img={CourseImg1}
                  title="Reiki Course"
                  text="Purify and empower your life energies - Prana, Chaitanya, and Kundalini Shakti with our Reiki attunements."
                />
              </Link>
            </CourseBox>
            <CourseBox>
              <Link to="/reiki-1st-and-2nd-degree">
                <ProjectBox
                  img={CourseImg2}
                  title="Reiki 1st and 2nd Degree"
                  text="Uplift your life Prana & Consciousness with our progressive 1st & 2nd degree Reiki course."
                />
              </Link>
            </CourseBox>
            <CourseBox>
              <Link to="/reiki-3rd-degree">
                <ProjectBox
                  img={Reiki3rdDegree}
                  title="Reiki 3rd Degree"
                  text="Initiate your Kundalini Shakti (Serpentine Power) purification & activation with our 3rd degree Reiki course."
                />
              </Link>
            </CourseBox>
            <CourseBox>
              <Link to="/pendulum">
                <ProjectBox
                  img={CourseImg4}
                  title="Pendulum(Dowsing)"
                  text="Know about yourself and for yourself with pendulum power."
                />
              </Link>
            </CourseBox>
            <CourseBox>
              <Link to="/reiki-crystal-basic">
                <ProjectBox
                  img={CourseImg5}
                  title="Reiki Crystal Basic Course"
                  text="Make the positive power of your sadhana thousands of times more effective with crystals."
                />
              </Link>
            </CourseBox>
            <CourseBox>
              <Link to="/reiki-personalized">
                <ProjectBox
                  img={CourseImg6}
                  title="Reiki Crystal Personalized Course"
                  text="Achieve your life goals or purpose by using the right crystal for yourself."
                />
              </Link>
            </CourseBox>
          </CourseGrid>
        </div>
      </div>
    </Wrapper>
  );
}

// Styled Components

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 120px; /* Add margin below the header */
`;

const HeaderInfo = styled.div`
  text-align: center;
  padding: 0 20px;

  @media (max-width: 860px) {
    padding: 0 15px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem; /* Increased font size */
  font-weight: bold;
  color: #04016a; /* Text color set to #04016a */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3),
    /* Soft shadow for text */ 0px 0px 10px #ffeb80; /* Glow effect */
  margin-bottom: 20px; /* Space below the title */
  padding: 10px 0;
  border-radius: 5px; /* Optional: add some border-radius for rounded corners */

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #333;
  background: rgba(255, 255, 0, 0.2); /* Light yellow background */
  border-left: 5px solid #04016a; /* Left border for emphasis */
  padding: 15px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  line-height: 1.6; /* Line height for readability */
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.4); /* Darker on hover */
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const CourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three courses per row by default */
  gap: 20px;
  justify-items: center;
  margin-top: 40px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Two courses per row on tablets */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One course per row on mobile */
  }
`;

const CourseBox = styled.div`
  width: 100%; /* Ensure each box takes full width of its grid cell */
  max-width: 380px; /* Max width for better spacing */
  margin: 0 auto; /* Center the course box in the grid cell */
`;

// Ensure that the title in ProjectBox is centered
const ProjectBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: space-between; /* Space between image and text */
  text-align: center; /* Center the title text */
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    max-width: 350px; /* Ensure images are contained */
    height: auto;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #04016a;
  }

  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
`;

export { ProjectBoxStyled };

// Pseudo-element for anchor offset
const AnchorOffset = styled.div`
  content: "";
  display: block;
  height: 80px; /* Adjust for navbar height */
  margin-top: -80px; /* Offset section correctly */
`;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet"; // Correct import for Helmet
import MainContent from "./MainContent";
import RoutesComponent from "./routes"; // Import your routes

function App() {
  return (
    <Router>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <MainContent />
      <RoutesComponent /> {/* Render routes from the routes file */}
    </Router>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import LogoImg from "../../assets/img/Cover.jpg";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  const handleScrollToSection = (section) => {
    if (location.pathname === "/") {
      // Scroll on the same page if already on the homepage
      scroller.scrollTo(section, {
        smooth: true,
        offset: -80, // adjust this offset as needed
        duration: 500,
      });
    } else {
      // Navigate to homepage with scrollTo parameter if on a different page
      window.location.href = `/?scrollTo=${section}`;
    }
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="royalBlueBg" style={{ minHeight: "100px" }}>
        <Container>
          <InnerWrapper>
            <LogoAndAddressSection>
              <LogoContainer onClick={() => handleScrollToSection("home")}>
                <Logo src={LogoImg} alt="Logo" />
              </LogoContainer>
              <LogoTextAndAddressWrapper>
                <LogoText>
                  AMRUTTAM RESEARCH INSTITUTE FOR REIKI & YOGA PVT. LTD.
                </LogoText>
                <AddressWrapper>
                  <AddressText>
                    Address: HG2, INTERNATIONAL BUSINESS CENTRE, Dumas Rd,
                    Piplod, Surat, Gujarat 395007
                  </AddressText>
                  <OfficeNumber>
                    Office No:{" "}
                    <HighlightedNumber href="tel:+919909000539">
                      +91 9909000539
                    </HighlightedNumber>
                  </OfficeNumber>
                </AddressWrapper>
              </LogoTextAndAddressWrapper>
            </LogoAndAddressSection>

            <LinksWrapper>
              {["home", "courses", "services", "products", "contact"].map(
                (section) => (
                  <FooterLink
                    key={section}
                    onClick={() => handleScrollToSection(section)}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </FooterLink>
                )
              )}
            </LinksWrapper>

            <SocialMediaWrapper>
              <SocialMediaTitle>Follow Us:</SocialMediaTitle>
              <SocialMediaLinks>
                <SocialLink
                  href="http://Facebook.com/amruttamreikiyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </SocialLink>
                <SocialLink
                  href="https://www.instagram.com/amruttamreikiyogapvtltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </SocialLink>
                <SocialLink
                  href="https://x.com/AmruttamReikiY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </SocialLink>
                <SocialLink
                  href="https://www.youtube.com/@amruttamreikiyogapvtltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </SocialLink>
                <SocialLink
                  href="https://www.pinterest.com/amruttamreikiyoga/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPinterest />
                </SocialLink>
                <SocialLink
                  href="http://Linkedin.com/company/amruttamreikiyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </SocialLink>
              </SocialMediaLinks>
            </SocialMediaWrapper>
          </InnerWrapper>
        </Container>
        <StyleP className="whiteColor font13">
          © {getCurrentYear()} -{" "}
          <span className="whiteColor font13" style={{ marginRight: "5px" }}>
            AMRUTTAM RESEARCH INSTITUTE FOR REIKI & YOGA PVT. LTD.
          </span>
          All Rights Reserved
        </StyleP>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #333;
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    padding: 40px 0;
  }
`;

const LogoAndAddressSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    width: auto;
    margin-bottom: 0;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const LogoTextAndAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 1200px) {
    width: 200px;
  }
`;

const LogoText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
    text-align: left;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
`;

const AddressWrapper = styled.div`
  color: whitesmoke;
  margin-top: 10px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const AddressText = styled.p`
  color: whitesmoke;
  margin: 5px 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

const OfficeNumber = styled.p`
  color: whitesmoke;
  margin: 5px 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

const HighlightedNumber = styled.a`
  font-weight: bold;
  color: #ffd700;
  text-decoration: none;

  &:hover {
    color: #ffc107; /* Optional hover effect */
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    gap: 15px;
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-top: 0;
  }
`;

const SocialMediaTitle = styled.h4`
  color: white;
  margin-bottom: 10px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const SocialMediaLinks = styled.div`
  display: flex;
  gap: 10px;
`;

const SocialLink = styled.a`
  color: white;
  font-size: 20px;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
`;

const StyleP = styled.p`
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 13px;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 15px;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import CourseImg1 from "../../assets/img/courses/Course1.png";

export default function Reiki() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Reiki Course</Title>
          <CourseImage1Styled src={CourseImg1} alt="Reiki" />
        </MainHeading>
        <SecondaryHeading>
          <IntroParagraph>
            Before we delve into our courses let’s understand,{" "}
            <HighlightedText>What is Reiki?</HighlightedText>
          </IntroParagraph>

          <Paragraph>
            Reiki is a Japanese healing technique, as the founder of this
            technique, Dr. Mikao Usui was from Japan. If we search on the web,
            we can get plenty of theories & literature on Dr. Mikao Usui, him
            achieving the siddhi of Reiki, the history behind unparalleled
            healing techniques of current time.
          </Paragraph>

          <Paragraph>
            However, here is what we would like to share with our readers. How
            our Founder Director Bharatt Mistry & Director of Training Anjana
            Mistry started this research institute, how the Reiki technique has
            shaped our lives, and some miraculous but absolutely stunning
            theories of Reiki energy based on Energy science.
          </Paragraph>

          <Paragraph>
            In simple terms, ‘Rei’- Universal ‘Ki’- Life force energy.
          </Paragraph>
          <Paragraph>
            In Indian language it is called ‘विश्व व्यापी जीवन शक्ति/ प्राण
            शक्ति, चैतन्य शक्ति & कुंडलनी शक्ति’. In the Scientific language
            it’s called ‘Elementary energy’ & in chinese, it is called Chi, In
            Yogic language Reiki is the combination of 3 main life forces, Prana
            Shakti (Life force energy), Chaitanya shakti (Consciousness),
            Kundalini shakti (Serpentine power), from which the Panchamahabhutas
            (5 great elements) are made, which are- 1. Space 2. Air 3. Fire 4.
            Water 5. Earth As per yog shastra, the entire universe is made of
            these 5 elements. Thus we can say that the entire world is made from
            Reiki.
          </Paragraph>

          <Paragraph>
            We can purify & strengthen all 5 elements & their 3 fundamental
            energies with our Reiki attunement.
          </Paragraph>

          <Paragraph>
            The Reiki attunement developed by Reiki master Bharatt Mistry could
            expand the (nadis) subtle energy channels. In the Reiki 1st & 2nd
            degree attunement itself, it contains the power to heal the entire
            Pran Shakti & Chaitanya Shakti of a person. The 3rd degree Reiki
            attunement contains the power of healing Kundalini Shakti of a
            person. The power to unravel & heal the 3 main life forces of human
            existence, the foundational aspects of life Prana Shakti, Chaitanya
            Shakti & Kundalini Shakti, is smoothly achieved in our Reiki
            attunements. The results achieved by us and our students are a
            testament to our effectiveness.
          </Paragraph>

          <Paragraph>
            The 1st, 2nd, & 3rd degree Reiki attunements developed & researched
            by Reiki master Bharatt Mistry, in themselves are capable of healing
            all life aspects by healing these 3 main forces that creates life.
          </Paragraph>

          <Paragraph>
            Empower yourself with our Reiki courses. Your journey to a
            healthier, happier life starts with one simple step and your
            commitment to self-improvement.
          </Paragraph>

          <Paragraph>
            In addition to our 3rd degree master power course, we also offer
            Crystal Reiki Basic & Crystal Reiki personalized courses. In between
            Reiki 3rd degree & Reiki Crystal courses, we are bringing Pendulum
            dowsing course in the near future. Where you can uncover the
            knowledge to get answers to your life problems & questions. Where
            you will learn the 12 main reasons any negative force is generated
            or enters in our life. Which will make you independent of finding
            answers & solutions to your life, empowering you for greater success
            .
          </Paragraph>

          <Paragraph>
            With over 25 years of continuous experience and extremely dedicated
            Sadhna to Reiki, crystals and energy science, our Founder Director,
            Reiki Master Mr. Bharatt Mistry, has led our Reiki course to its
            highly advanced status in the world. His commitment to personal
            growth and healing has enabled us to deliver impressive results in a
            short time. Our students from around the globe have shared their
            positive experiences and remarkable results
          </Paragraph>

          <CertificationNote>
            Our Reiki courses culminate in a certification upon completion.
          </CertificationNote>

          <SubHeading>
            The key highlights of our Reiki courses are as under
          </SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Continuous Result Based Research</strong>: Our course
                evolves every day with cutting-edge research in Reiki, crystals,
                and energy science.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Faster Results, Deeper Healing</strong>: Experience
                faster energy shifts and deeper healing with advanced attunement
                techniques.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Remove Energy Barriers</strong>: Learn to understand and
                dissolve the energy constitutions of negative karma.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Comprehensive Course</strong>: Our in-depth program
                explores every aspect of life, empowering you with knowledge and
                tools for balanced growth.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Guidance for Success</strong>: Gain insights to navigate
                your journey toward a successful and virtuous life.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Global Success Stories</strong>: Students worldwide have
                experienced rapid, life-changing transformations through our
                Reiki course.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>25 Years of Mastery</strong>: Decades of dedicated
                Sadhana and Research by Reiki Master Bharatt Mistry have led to
                one of the world’s most advanced Reiki courses.
              </BulletText>
            </BulletItem>
          </BulletList>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a; /* Background color */
  padding: 15px 20px; /* Padding */
  border-radius: 8px; /* Rounded corners */
  margin: 30px 0 15px; /* Margin */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  /* Responsive styles */
  @media (max-width: 1200px) {
    font-size: 1.8rem; /* Slightly smaller for medium screens */
  }

  @media (max-width: 900px) {
    font-size: 1.6rem; /* Smaller for smaller tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.4rem; /* Smaller for mobile devices */
    padding: 10px 15px; /* Adjust padding */
  }

  @media (max-width: 400px) {
    font-size: 1.2rem; /* Even smaller for very small screens */
    padding: 8px 12px; /* Further adjust padding */
  }
`;

const IntroParagraph = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const HighlightedText = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
  color: #ff5722; /* Eye-catching color for emphasis */
  display: block;
  margin-top: 10px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const CourseImage1Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%; /* Increase width */
  margin: 20px auto; /* Center it */
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align bullet and text */
  margin-bottom: 15px; /* Increased spacing */
  line-height: 1.5;
  padding-left: 10px; /* Additional padding for spacing */
  text-align: left;
  font-size: 1.1rem;
  color: #333; /* Darker text color */
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px; /* Space between bullet and text */
`;

const CertificationNote = styled.p`
  font-weight: bold;
  color: #000;
  margin: 20px 0;
  font-size: 1.2rem;
`;

const BulletText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  background-color: #f9f9f9; /* Light background for list items */
  border-radius: 5px; /* Rounded corners for list items */
  padding: 10px; /* Padding inside list items */
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff; /* Light blue background on hover */
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px);
  }

  &:hover svg {
    transform: translateX(-5px);
    transition: transform 0.3s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    top: 10px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px;
  }

  @media (max-width: 480px) {
    bottom: 80px;
  }
`;

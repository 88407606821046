import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={handleCloseSidebar} />
      {sidebarOpen && <Backdrop onClick={handleCloseSidebar} />}
      <Wrapper className="flexCenter animate whiteBg">
        <NavInner className="container flexSpaceBetween">
          <LogoContainer>
            <a href="#home" style={{ display: "flex", alignItems: "center" }}>
              <LogoIcon />
              <h1
                className="font20 extraBold"
                style={{ marginLeft: "10px", lineHeight: "1.4" }}
              >
                Amruttam Research Institute for <br />
                <span style={{ display: "block" }}>
                  Reiki and Yoga Pvt. Ltd.
                </span>
              </h1>
            </a>
          </LogoContainer>

          {/* Mobile Hamburger Menu */}
          <BurgerWrapper className="pointer" onClick={handleToggleSidebar}>
            <BurgerIcon />
          </BurgerWrapper>

          {/* This could be the full navbar when on larger screens (not visible on mobile) */}
          <NavLinks className="desktopNav">
            <ul className="flex">
              {["Home", "Courses", "Services", "Products", "Contact"].map(
                (item, index) => (
                  <li key={index}>
                    <a href={`#${item.toLowerCase()}`} className="navLink">
                      {item}
                    </a>
                  </li>
                )
              )}
            </ul>
          </NavLinks>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;

  @media (min-width: 768px) {
    display: none; // Hide for larger screens
  }
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @media (max-width: 760px) {
    padding: 0 15px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px; /* Default small space for larger screens */

  @media (max-width: 768px) {
    margin-top: 15px; /* Space for smaller screens */
  }

  @media (min-width: 1200px) {
    margin-top: 20px; /* Larger space for larger screens (desktops) */
  }
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0;
  display: block; // Always show on mobile

  @media (min-width: 768px) {
    display: none; // Hide on larger screens
  }
`;

const NavLinks = styled.div`
  display: none;

  .desktopNav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin: 0 15px;
    }

    .navLink {
      text-decoration: none;
      color: #04016a;
      font-weight: bold;
      font-size: 1rem;
      padding: 8px 0;
      &:hover {
        color: #ffeb80;
      }
    }
  }

  @media (min-width: 768px) {
    display: none; // Hide by default on small screens
  }

  @media (min-width: 1024px) {
    display: flex; // Show on larger screens
  }
`;

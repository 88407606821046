import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import LogoIcon from "../../assets/svg/Logo";

export default function Sidebar({ sidebarOpen, closeSidebar }) {
  return (
    <Wrapper className={`sidebar animate darkBg`} sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h1
            className="font20 extraBold"
            style={{ marginLeft: "10px", lineHeight: "1.4" }}
          >
            Amruttam Research Institute for <br />
            <span style={{ display: "block" }}>Reiki and Yoga Pvt. Ltd.</span>
          </h1>
        </div>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {["home", "courses", "services", "products", "contact"].map((item) => (
          <li key={item} className="semiBold font15 pointer">
            <Link
              onClick={closeSidebar} // Close sidebar on link click
              to={item}
              spy={true}
              smooth={true}
              offset={-60}
              className="whiteColor"
              activeClass="active-link" // Add activeClass here
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </Link>
          </li>
        ))}
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 80%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-80%")};
  z-index: 9999;
  transition: right 0.3s ease;
  background-color: #04016a;

  @media (min-width: 768px) {
    display: none; // Hide sidebar on larger screens (desktops/tablets)
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; // Adjust spacing for logo and title

  h1 {
    font-size: 1.5rem;
    margin-left: 15px;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 1.8rem; // Slightly larger font on bigger screens
    }
  }
`;

const UlStyle = styled.ul`
  padding: 40px;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    margin: 20px 0;
    display: flex;
    justify-content: center;

    a {
      font-size: 1.2rem; // Base font size for links

      @media (min-width: 768px) {
        font-size: 1rem; // Smaller font on larger screens (not visible anyway)
      }
    }
  }

  .active-link {
    color: #ffeb80;
    font-weight: bold;
    border-bottom: 2px solid #ffeb80;
    padding-bottom: 4px;
  }

  @media (min-width: 768px) {
    padding: 30px 20px; // Adjust padding on larger screens for better layout
  }
`;

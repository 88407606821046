import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CourseImg2 from "../../assets/img/courses/Course2.png";

export default function Reiki1stAnd2ndDegree() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This navigates the user back to their last page
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Reiki 1st and 2nd Degree</Title>
          <CourseImage2Styled src={CourseImg2} alt="Reiki 1st and 2nd degree" />
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Our 1st & 2nd degree Reiki attunement activates nadis (energy
            channels) connecting with all 12 chakras & other related energy
            centers from infinite distance above the head to infinite distance
            below both feet at once. This enables the Reiki channel to heal
            faster & achieve positive results quicker. Our 1st & 2nd degree
            Reiki power heals the ‘Pran shakti’ (Vital force energy) and
            ‘Chaitanya shakti’ (Consciousness) of the Reiki channel.
          </Paragraph>

          <SubHeading>Course Duration & Structure</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Length</strong>: 130 hours
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Classes</strong>: Regular sessions, 5 days a week
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Duration</strong>: 1.5 hours per day
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Content</strong>: New developed course of 1st & 2nd
                degree focused on result-based healing
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Course Features</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Audio recordings of all practical sessions
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Personalized consultations</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                One-on-one in-class sessions (available as needed)
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Reiki Kit Includes</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Comprehensive course book (278 pages)</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Photo of Dr. Mikao Usui</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>4 foundational symbols plus 20 variations</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                4 specialized Reiki symbols developed through 20 years of
                research by Reiki Master Bharatt Mistry
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Amruttam Yantra (designed to enhance the intensity and results
                of Reiki power, researched and developed by Reiki Master Bharatt
                Mistry)
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Kit bag</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Counter, pen, and additional stationery items
              </BulletText>
            </BulletItem>
          </BulletList>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px;
  background-color: #f4f4f4;
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-top: 60px;
  }
`;

const CourseImage2Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 15px;
  }
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a;
  padding: 15px 20px;
  border-radius: 8px;
  margin: 30px 0 15px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 1200px) {
    font-size: 1.8rem;
  }

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }

  @media (max-width: 600px) {
    font-size: 1.4rem;
    padding: 10px 15px;
  }

  @media (max-width: 400px) {
    font-size: 1.2rem;
    padding: 8px 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%;
  margin: 20px auto;
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1.5;
  padding-left: 10px;
  text-align: left;
  font-size: 1.1rem;
  color: #333;
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const BulletText = styled.span`
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px);
  }

  @media (max-width: 768px) {
    top: 10px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px;
  }

  @media (max-width: 480px) {
    bottom: 80px;
  }
`;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TestimonialSlider from "../Elements/TestimonialSlider";
import ARPGImage from "../../assets/img/products/arpg.png";
import AmruttamBatti from "../../assets/img/products/amruttam-batti.jpg";
import Protector from "../../assets/img/products/protector.png";

export default function Products() {
  return (
    <Wrapper id="products">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <Title>Our Products</Title>
            <Description>
              Explore our selection of high-quality products designed to enhance
              your wellness journey.
            </Description>
          </HeaderInfo>
          <ProductBoxRow>
            {productData.map((product, index) => (
              <ProductBoxWrapper key={index}>
                <Link to={product.link}>
                  <img src={product.image} alt={product.name} />
                  <h3>{product.name}</h3>
                </Link>
                <p>{product.description}</p>
                <LinkButton to={product.link}>Know More</LinkButton>
              </ProductBoxWrapper>
            ))}
          </ProductBoxRow>
          <VisitStore>
            <p>
              To see more products,{" "}
              <Link to="/store" rel="noopener noreferrer">
                Visit
              </Link>{" "}
              our store.
            </p>
          </VisitStore>
        </div>
      </div>
      <br />
      <TestimonialInfo>
        <h1 className="font40 extraBold">What They Say?</h1>
        <p className="font15">
          Explore heartfelt reviews from our students, sharing their
          transformative experiences with Amruttam Reiki Yoga.
        </p>
      </TestimonialInfo>
      <TestimonialSlider />
      <br />
    </Wrapper>
  );
}

const productData = [
  {
    name: "ARPG",
    image: ARPGImage,
    description: "Enhance your life energy & consciousness with ARPG.",
    link: "/arpg",
  },
  {
    name: "Amruttam Batti",
    image: AmruttamBatti,
    description:
      "Reiki charged natural incense stick for your sublime atmosphere.",
    link: "/amruttam-batti",
  },
  {
    name: "Life Protector",
    image: Protector,
    description:
      "Crystal hangings to protect & enhance your energy & well being.",
    link: "/life-protector",
  },
];

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 120px; /* Add margin below the header */
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const TestimonialInfo = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #04016a;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 10px #ffeb80;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #34495e;
  padding: 15px;
  border-left: 5px solid #04016a;
  background: rgba(255, 255, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  line-height: 1.6;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ProductBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%; /* Added gap to ensure consistent spacing between items */
  justify-content: center;
  margin: 40px 0;

  @media (max-width: 1024px) {
    justify-content: space-between; /* More space between items */
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const LinkButton = styled(Link)`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #04016a;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: #60669f 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #515195;
    transform: scale(1.05);
  }
`;

const ProductBoxWrapper = styled.div`
  width: 32%; /* Default to 3 per row on large screens */
  max-width: 320px;
  height: 480px; /* Fixed height for the card */
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Distribute space between content and button */

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 200px; /* Fixed height for the image */
    object-fit: cover;
    border-radius: 10px;
  }

  h3 {
    margin: 10px 0;
    color: #2c3e50;
    font-size: 1.2em;
  }

  p {
    color: #34495e;
    margin: 10px 0;
    font-size: 0.9em;
    flex-grow: 1; /* Allow description to grow and take available space */
    overflow: hidden; /* Prevent content from overflowing */
    text-overflow: ellipsis; /* Ensure long descriptions are truncated */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Optional: limit the number of lines for the description */
    -webkit-box-orient: vertical;
  }

  @media (max-width: 1024px) {
    width: 48%; /* 2 per row on medium screens */
    height: auto; /* Auto adjust height */
  }

  @media (max-width: 768px) {
    width: 100%; /* 1 per row on small screens */
    height: auto; /* Auto adjust height */
  }

  @media (max-width: 480px) {
    padding: 15px; /* Reduce padding on very small screens */
  }
`;

const VisitStore = styled.div`
  text-align: center;
  margin-top: 20px;
  p {
    font-size: 1.1em;
    color: #34495e;
  }

  a {
    color: #e74c3c;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
`;

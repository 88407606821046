import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Protector from "../../assets/img/products/protector.png";

export default function LifeProtector() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Life Protector</Title>
          <ProtectorImageStyled src={Protector} alt="LifeProtector" />
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Life Protector is a device designed to protect life across different
            dimensions, offering thousands of positive effects. Life Protector
            is made of clear quartz pencils and rose quartz pencils. The
            fundamental principle behind designing the Life Protector is to
            activate and energize all chakras
          </Paragraph>

          <SubHeading>
            Life Protector offers around 90 amazing uses to support your
            well-being in so many ways!
          </SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhance Immunity and Mental Clarity</strong>: It
                energizes the chakras and indirectly activates all nadi tantra
                energies, enhancing immunity and improving physical and mental
                efficiency, bringing peace and freshness to the mind
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Positive Home Energy</strong>: Fills your home with
                uplifting energy, promoting a harmonious living environment
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Kitchen Harmony</strong>: Maintains a positive
                atmosphere in the kitchen, enhancing your cooking experience
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Provides Positive Energy</strong>: Beneficial for those
                in poor health or nearing the end of life, providing positive
                energy
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhances Educational Success</strong>: Enhances students
                concentration and focus, fostering an environment conducive to
                learning and ultimately leading to improved academic performance
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Purifies Prenatal Energy</strong>: Ensures the food
                consumed by pregnant women is purified for healthy fetal
                development
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Finds Soulmates</strong>: Increases the likelihood of
                unmarried individuals finding suitable life partners
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhances Marital Harmony</strong>: Improves
                relationships between couples, fostering love and understanding
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Suppresses Bad Dreams</strong>: Helps in reducing
                nightmares for peaceful sleep
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Shoe & Storage Area Cleansing</strong>: Dissipates
                negative energy from shoe storage and storerooms
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Travel Safety</strong>: Ensures the safety of passengers
                and vehicles during travel
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Some applications of the Life Protector can be used alone
                without any stones, while others require additional stone
                support.
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Contact Details</SubHeading>
          <ContactBox>
            <ContactItem>
              <a href="tel:+919909000539">📞 Contact: +91 9909000539</a>
            </ContactItem>
            <ContactItem>
              <a
                href="https://maps.app.goo.gl/cxtGGoyumwyHRQkr9"
                target="_blank"
                rel="noopener noreferrer"
              >
                🏢 Address: HG-2, International Business Centre, Piplod, Surat
                395007
              </a>
            </ContactItem>
            <ContactItem>
              <a href="mailto:amruttamreikiyoga@gmail.com">
                📧 Email: amruttamreikiyoga@gmail.com
              </a>
            </ContactItem>
          </ContactBox>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px;
  background-color: #f4f4f4;

  @media (max-width: 768px) {
    padding: 20px; /* Less padding for smaller devices */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px; /* Padding for small screens */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
    margin-top: 50px;
  }
`;

const ProtectorImageStyled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a;
  padding: 15px 20px;
  border-radius: 8px;
  margin: 30px 0 15px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%;
  margin: 20px auto;
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1.5;
  padding-left: 10px;
  text-align: left;
  font-size: 1.1rem;
  color: #333;
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const BulletText = styled.span`
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff;
  }
`;

const ContactBox = styled.div`
  background-color: #f0f8ff;
  border: 1px solid #b0c4de;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const ContactItem = styled.div`
  font-size: 1.1rem;
  margin: 10px 0;
  color: #333;
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100;

  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px);
  }

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    top: 10px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px;
  }

  @media (max-width: 480px) {
    bottom: 80px;
  }
`;

import React, { useRef, useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import HeaderImage from "../../assets/img/Dr. Usui.png";
import Dots from "../../assets/svg/Dots";
import { Link } from "react-router-dom";

export default function Header() {
  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    // Set initial image width
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }

    // Optional: Update image width on window resize
    const handleResize = () => {
      if (imageRef.current) {
        setImageWidth(imageRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <ContentWrapper>
            <h1
              className="extraBold"
              style={{ fontFamily: "Times New Roman, serif", fontSize: "33px" }}
            >
              AMRUTTAM RESEARCH INSTITUTE FOR REIKI & YOGA PVT. LTD.
            </h1>
            <HeaderP className="semiBold">
              We offer holistic healing and wellness through the powerful
              combination of Reiki and yoga, helping you achieve balance and
              <Highlight>MEDICINE-FREE LIFE.</Highlight>
            </HeaderP>
            <AboutButton to="/about">About Us</AboutButton>
          </ContentWrapper>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img
              ref={imageRef}
              className="radius8"
              src={HeaderImage}
              alt="office"
            />
            <AuthorTextWrapper>
              <AuthorText>Dr. Mikao Usui</AuthorText>
            </AuthorTextWrapper>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
            {/* Pass imageWidth to BorderShadow */}
            <BorderShadow imageWidth={imageWidth} />
          </ImageWrapper>
        </RightSide>
      </Wrapper>
    </>
  );
}

const AboutButton = styled(Link)`
  --color: #04016a;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s, background 0.5s, border 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: var(--color);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }

  &:hover {
    color: var(--color);
    background: #fff;
    border-color: var(--color);

    &::before {
      top: -30px;
      left: -30px;
      background: #fff;
    }
  }

  &:active::before {
    background: #3a0ca3;
    transition: background 0s;
  }
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 40px; /* Reduce padding on smaller screens */
  }
`;

const LeftSide = styled.div`
  width: 50%;
  text-align: left;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure both h1 and paragraph align vertically */
  align-items: flex-start; /* Align text to the left */
  padding: 0; /* Remove any unwanted padding */

  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    order: 2;
    margin: 50px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Start from left */
  justify-content: flex-start; /* Keep them aligned to the top */
  padding: 0;

  h1 {
    margin: 0;
  }

  p {
    margin-top: 20px; /* Add some margin between h1 and p */
  }

  @media (max-width: 960px) {
    align-items: center; /* Center the content on medium screens */
  }
`;

const Highlight = styled.span`
  color: #04016a;
  font-family: "Times New Roman", serif;
  font-size: 1.3rem;
  background: rgba(255, 255, 0, 0.3);
  padding: 2px 5px;
  border-radius: 10px;
`;

const RightSide = styled.div`
  width: 50%;
  position: relative;

  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  font-size: 1.28rem;
  color: #333;
  margin: 0;

  @media (max-width: 960px) {
    text-align: center;
    max-width: 100%;
    padding: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 9;
  animation: fadeIn 1s ease-in-out;
  width: 100%;
  justify-content: center;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;

const Img = styled.img`
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  width: 426px; /* Fixed width for the image */

  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;

  @media (max-width: 960px) {
    right: 50px;
    bottom: 50px;
  }

  @media (max-width: 560px) {
    display: none;
  }
`;

const AuthorTextWrapper = styled.div`
  width: 426px;
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, #ffeb3b, #ffffff);
  text-align: center;
  padding: 6px 0;
  z-index: 9;
  border-radius: 10px;

  @media (max-width: 960px) {
    width: 90%;
    bottom: -30px;
  }

  @media (max-width: 560px) {
    width: 85%;
    bottom: -38px;
  }
`;

const AuthorText = styled.p`
  font-size: 18px;
  color: #04016a;
  margin: 0;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  background: transparent;
`;

// Border shadow that adjusts based on image width
const BorderShadow = styled.div`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: ${(props) =>
    props.imageWidth || "426px"}; /* Use dynamic image width */
  height: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  z-index: 0;
`;

const GlobalStyle = createGlobalStyle`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

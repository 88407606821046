import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ARPGImage from "../../assets/img/products/arpg.png";

export default function ARPG() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This navigates the user back to their last page
  };
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Amruttam Reiki Powered Globules(ARPG)</Title>
          <ARPGImageStyled src={ARPGImage} alt="ARPG" />{" "}
          {/* ARPG Image Below Title */}
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Amruttam Reiki Powered Globules (ARPG) is a natural health product
            that helps improve energy and overall wellness. Each dose contains
            special globules that offer the benefits of Reiki healing, helping
            with various health issues, from digestion to emotional support.
            It’s easy to order, and you’ll get free delivery right to your door.
          </Paragraph>

          <SubHeading>Key Benefits of ARPG</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Body and Mind Healing</strong>: ARPG provides powerful
                healing benefits, addressing over 200+ physical conditions and
                supporting recovery from 90% of mental health issues for
                complete, balanced health
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Mental Well-being</strong>: Helps eliminate mental
                disorders like depression, anxiety etc. and provides clarity,
                promoting a state of calm and improved mental health
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Medicine-Free</strong>: ARPG is a holistic product that
                provides wellness benefits with no medicine involved
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Heart Health Support</strong>: Effective for
                heart-related issues such as heart block, bypass, and
                post-cardiac surgery recovery, providing a gentle energy boost
                and aiding in faster recovery
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Liver and Pancreas Care</strong>: Useful for conditions
                involving the liver and pancreas, aiding in digestive health and
                managing associated diseases
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Comprehensive Health Benefits</strong>: ARPG aids in
                managing symptoms of common ailments and more specific health
                issues, such as: Cough and fever relief, Heel pain (plantar
                fasciitis) support, Lower back pain and shoulder injury relief
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Maternal and Child Health</strong>: Ensures up to 98%
                normal childbirth, supports pregnant women’s health, and
                promotes overall wellness for infants and children. ARPG also
                assists in behavioral improvements for children
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Kidney Health</strong>: Addresses kidney-related
                conditions, including those requiring dialysis, and supports the
                management of elevated creatinine levels
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Financial and Life Development</strong>: Facilitates an
                energetic shift to support financial growth and development in
                life pursuits
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Energy Revitalization</strong>: Supports revitalizing
                the body's energy, enhancing focus, and improving memory and
                recollection capabilities
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Business Growth</strong>: ARPG helps your business
                expand and encourages new ideas
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Dosage & Usage</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Dose</strong>: Take a minimum 3 globules at a time
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Bottle</strong>: Each bottle has about 60-63 doses
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Box</strong>: Each box contains around 190 doses
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Healing Power</strong>: Each globule gives you the
                benefits of a 40-45 minute Reiki healing session
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Usage Instructions</strong>: You will receive simple
                instructions on how to use ARPG upon your purchase
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Ordering Details</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Easy Ordering</strong>: Simply call us to order your
                Amruttam Reiki Powered Globules for a smooth and convenient
                experience
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Free Home Delivery</strong>: Get free delivery straight
                to your door with every order
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Box Contents</strong>: Each box includes three bottles,
                providing you with a long-lasting supply
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Price</strong>: One box is priced at{" "}
                <PriceHighlight>₹1350/- INR</PriceHighlight>
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Note</strong>: For free home delivery, you need to order
                a minimum of one box, which includes three bottles
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Contact Details</SubHeading>
          <ContactBox>
            <ContactItem>
              <a href="tel:+919909000539">📞 Contact: +91 9909000539</a>
            </ContactItem>
            <ContactItem>
              <a
                href="https://maps.app.goo.gl/cxtGGoyumwyHRQkr9"
                target="_blank"
                rel="noopener noreferrer"
              >
                🏢 Address: HG-2, International Business Centre, Piplod, Surat
                395007
              </a>
            </ContactItem>
            <ContactItem>
              <a href="mailto:amruttamreikiyoga@gmail.com">
                📧 Email: amruttamreikiyoga@gmail.com
              </a>
            </ContactItem>
          </ContactBox>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px;
  background-color: #f4f4f4;

  @media (max-width: 768px) {
    padding: 20px; /* Less padding for smaller devices */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px; /* Padding for small screens */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const ARPGImageStyled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a;
  padding: 15px 20px;
  border-radius: 8px;
  margin: 30px 0 15px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%;
  margin: 20px auto;
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1.5;
  padding-left: 10px;
  text-align: left;
  font-size: 1.1rem;
  color: #333;
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const BulletText = styled.span`
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff;
  }
`;

const PriceHighlight = styled.span`
  font-weight: bold;
  color: #aa6c39; /* Highlight color */
  font-size: 1.2rem; /* Slightly larger font */
`;

const ContactBox = styled.div`
  margin-top: 30px;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
    padding-left: 10px;
  }
`;

const ContactItem = styled.div`
  margin-bottom: 10px;

  & a {
    font-size: 1.1rem;
    text-decoration: none;
    color: #04016a;
    display: block;
    transition: color 0.2s;

    &:hover {
      color: #0503b0;
    }

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 0.9rem;
    }
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 20px; /* No margin for larger screens */

  @media (max-width: 1024px) {
    margin-top: 60px; /* Adds space on medium-sized screens (e.g., tablets) */
  }

  @media (max-width: 768px) {
    margin-top: 70px; /* Adds more space for smaller screens (e.g., tablets in portrait) */
  }

  @media (max-width: 480px) {
    margin-top: 90px; /* Adds more space for small phones */
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px; /* Positioned at the top */
  left: 20px;
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px);
  }

  &:hover svg {
    transform: translateX(-5px);
    transition: transform 0.3s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    top: 10px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px;
  }

  @media (max-width: 480px) {
    bottom: 80px;
  }
`;

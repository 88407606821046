import React from "react";
import styled from "styled-components";
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ text, author, stars }) {
  return (
    <Wrapper className="darkBg flexNullCenter flexColumn">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <p className="whiteColor font13" style={{ paddingBottom: "30px" }}>
        {text}
      </p>
      <AuthorWrapper className="flexRow">
        <StarRating stars={stars} />
        <p className="orangeColor font13" style={{ marginLeft: "auto" }}>
          <em>{author}</em>
        </p>
      </AuthorWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #04016a;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-top: 10px;
  }

  @media (min-width: 769px) and (max-width: 1214px) {
    padding: 25px 20px;
    margin-top: 25px;
  }
`;

const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;

  /* Allow the author name to wrap and avoid overflow */
  flex-wrap: wrap;
`;

const StarRating = ({ stars }) => {
  return (
    <StarWrapper>
      {[...Array(5)].map((_, index) => (
        <Star key={index} filled={index < stars}>
          ★
        </Star>
      ))}
    </StarWrapper>
  );
};

const StarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 15px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    margin-right: 5px;
  }
`;

const Star = styled.span`
  color: ${({ filled }) => (filled ? "#FFD700" : "#e0e0e0")};
  font-size: 24px;
  margin: 0 2px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 0 1px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

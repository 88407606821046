import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll"; // Link from react-scroll
import { Link as RouterLink } from "react-router-dom"; // Link from react-router-dom
import styled from "styled-components";
import LogoIcon from "../../assets/svg/Logo";

export default function DesktopNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For mobile menu toggle

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper>
      <NavInner>
        <LogoContainer>
          {/* Use RouterLink for homepage navigation */}
          <RouterLink to="/" style={{ display: "flex", alignItems: "center" }}>
            <LogoIcon />
            <h1
              className="font20 extraBold"
              style={{ marginLeft: "10px", lineHeight: "1.4" }}
            >
              Amruttam Research Institute for <br />
              <span style={{ display: "block" }}>Reiki and Yoga Pvt. Ltd.</span>
            </h1>
          </RouterLink>
        </LogoContainer>

        {/* Toggle button for mobile */}
        <HamburgerIcon onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </HamburgerIcon>

        <NavLinks className={isMenuOpen ? "open" : ""}>
          {["home", "courses", "services", "products", "contact"].map(
            (item) => (
              <NavItem key={item}>
                <StyledLink
                  activeClass="active"
                  to={item}
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </StyledLink>
              </NavItem>
            )
          )}
        </NavLinks>
      </NavInner>
    </Wrapper>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 20px 50px;

  @media (max-width: 1024px) {
    padding: 20px 30px; /* Smaller padding on tablets */
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
  }
`;

const NavInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  height: 70px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  /* Hide menu on small screens by default */
  @media (max-width: 767px) {
    display: ${(props) => (props.className === "open" ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    background-color: #fff;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NavItem = styled.li`
  margin: 0 15px;
  padding: 10px 0;

  @media (max-width: 767px) {
    margin: 10px 0;
  }
`;

const StyledLink = styled(ScrollLink)`
  text-decoration: none;
  color: #04016a;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  display: inline-block;

  /* 3D white shadow effect */
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);

  &.active {
    color: #04016a; /* Active text color */
    font-weight: bold;
    background-color: #fff; /* Active background color */
    padding: 5px 10px;
    border-radius: 4px;
  }

  &:hover {
    color: #04016a;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }

  /* Smooth transition */
  transition: all 0.3s ease;
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;

  .bar {
    width: 30px;
    height: 4px;
    background-color: #04016a;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I got a reference from one of my relatives about this institute and I wanted to learn reiki and healing practices. I can say it was my best decision to connect with them and started learning about reiki and spiritual knowledge. I can say it's the best intuition to learn reiki and It changed my course of life. I am using their products like Amrutam Reiki powered globules and Amrutam batti since 2 years and it gave me extraordinary results."
            author="Aditi Pariawala"
            stars={5}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Joining this institute has been a life-changing experience for me. You will get to see an entirely different perspective of life and have a tool (Reiki) to find solutions to your perpetual problems. The standard of classes is excellent and the dedication attributed towards each student from these Reiki masters is way beyond expectations. Highly recommended to everyone for a better and meaningful life."
            author="V Lakhanpal"
            stars={5}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I learned reiki in Amruttam research center last year. I am very happy with my experience as they give deep knowledge of this subject and it's a life-changing experience. They are always ready to guide you. I am happy that I chose this institute to learn Reiki from. Even when your course is over, they are ready to help you in every possible way related to reiki. Once you join this institute, they become like your family."
            author="Priyanka"
            stars={5}
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  height: auto; /* Removed fixed height to allow for dynamic content */
  padding: 0 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  :focus-visible {
    outline: none;
    border: 0px;
  }

  /* Adjustments for medium screens (769px to 1214px) */
  @media (min-width: 769px) and (max-width: 1214px) {
    padding: 0 10%; /* Adjust padding for medium screens */
  }

  @media (max-width: 768px) {
    padding: 0 3%; /* Reduced padding on smaller screens */
  }

  @media (max-width: 480px) {
    padding: 0 2%; /* Even more reduced padding for mobile */
  }
`;

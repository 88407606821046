import React from "react";
import styled from "styled-components";
import BackgroundImage from "../../assets/img/office.jpg";

const Store = () => {
  return (
    <StoreWrapper>
      <BackgroundImg
        src={BackgroundImage}
        alt="Office Background"
        loading="lazy"
      />
      <MapLink
        href="https://maps.app.goo.gl/4eFTdPY752pYHLX1A"
        rel="noopener noreferrer"
      >
        🗺️✨ View Our Location 🌟🏢
      </MapLink>
    </StoreWrapper>
  );
};

const StoreWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the area without stretching */
  object-position: center center; /* Keeps image centered */
  z-index: -1; /* Ensure image stays behind content */
`;

const MapLink = styled.a`
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.8); /* Light background for readability */
  border-radius: 8px;
  font-size: 1.5em;
  color: #2c3e50;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  /* Hover Effect */
  &:hover {
    background: rgba(255, 255, 255, 1); /* Brighten background on hover */
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Slightly more prominent shadow on hover */
  }

  /* Responsive Styles for Tablets and Below */
  @media (max-width: 768px) {
    padding: 15px 30px; /* Adjust padding for tablets */
    font-size: 1.3em; /* Adjust font size for medium screens */
  }

  @media (max-width: 480px) {
    padding: 12px 25px; /* Adjust padding for mobile screens */
    font-size: 1.1em; /* Adjust font size for small screens */
  }
`;

export default Store;
